
/* eslint-disable camelcase */
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  inject,
  PropType,
  ref,
  SetupContext,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { ApmBase } from '@elastic/apm-rum';

/** Utils */
import {
  showSwalLoading,
  closeSwal,
  showSwalError,
  showSwalInfo,
} from '@/utils/sweetalert2-utils';
import {
  base64ToFile,
  currency,
  disableEncryption,
  showErrors,
  isAppleDevice,
} from '@/utils/general-utils';
import { ObligationTypes } from '@/utils/multi-pdf-types';

/** Interfaces */
import {
  DataPayPlaceToPayI,
  PaymentTypeI,
} from '@/interfaces/payment-detail-place-to-pay.interface';
import {
  ClosedObligationI,
  ResponseDownloadPdfI,
} from '@/interfaces/general.interface';
import {
  GetReferenceAndAgreementI,
  PlaceToPayRequestI,
  PlaceToPayResponseI,
} from '@/interfaces/placetopay.interface';

/** Services */
import { RequestDocumentService } from '@/services/request-document.service';
import { EncryptService } from '@/services/encrypt.service';

/** Composables */
import { useUtilities } from '@/composition/useUtilities';
import { usePaymentCard } from '@/composition/usePaymentCard';
import { useElasticTransactionApm } from '@/composition/useElasticTransactionApm';

export default defineComponent({
  name: 'ModalDetails',
  components: {
    FeesTable: defineAsyncComponent(
      () => import('@/components/shared/FeesTable.vue')
    ),
    ModalPaymentChannels: defineAsyncComponent(
      () => import('@/components/shared/ModalPaymentChannels.vue')
    ),
    Modal: defineAsyncComponent(
      () => import('@/components/shared/NewModal.vue')
    ),
    Loader: defineAsyncComponent(
      () => import('@/components/loader/Loader.vue')
    ),
    Tooltips: defineAsyncComponent(
      () => import('@/components/shared/Tooltips.vue')
    ),
  },
  props: {
    payPlan: {
      type: Object,
      required: false,
    },
    paymentDetail: {
      type: Object as PropType<DataPayPlaceToPayI>,
      required: true,
      default: () => Object.create({}) as DataPayPlaceToPayI,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadingQuotaSummary: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOtherOptions: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLoadingPaymentStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['confirm', 'close', 'open-modal'],
  setup(props, context: SetupContext) {
    const store = useStore();
    const $apm = inject('$apm') as ApmBase;

    const { customTransactioncApm, addTransaction, endTransactionApm } =
      useElasticTransactionApm();

    const paymentType: PaymentTypeI = {
      minPay: 'minPay',
      totalPay: 'totalPay',
      customPay: 'customPay',
    };
    const { validatePaymentDate } = useUtilities();
    const checkedTotalToPay = ref<string>(paymentType?.minPay);
    const totalToPay = ref(0);
    const showModalPaymentChannels = ref<boolean>(false);
    const minimunValueAgreement = 20000;
    const iPhoneLink = ref<unknown>({});

    const { canMakePayment } = usePaymentCard(context);

    watch(
      () => checkedTotalToPay.value,
      (value: string) => {
        if (value !== paymentType?.customPay) {
          totalToPay.value = 0;
        }
      }
    );

    const eventChecktoPay = computed(() => {
      const maxPayValue: number = props.paymentDetail?.valueMax ?? 0;
      const minPayValue: number = props.paymentDetail?.valueMin ?? 0;
      let value = 0;

      if (checkedTotalToPay.value === paymentType?.minPay) {
        value = minPayValue;
      } else if (checkedTotalToPay.value === paymentType?.totalPay) {
        value = maxPayValue;
      } else if (checkedTotalToPay.value === paymentType?.customPay) {
        value = totalToPay.value;
      }

      return value;
    });

    const hideFieldAnotherValue = computed<boolean>(() => {
      const minPayValue = Number(props.paymentDetail?.valueMin ?? 0);
      const maxPayValue = Number(props.paymentDetail?.valueMax ?? 0);

      return minPayValue === 0 && maxPayValue === 0;
    });

    const timestamp = computed((): string => {
      const dateUpdate = store.getters.getTimestamp;
      return dateUpdate ? new Date(dateUpdate).toLocaleDateString() : '';
    });

    const location = computed(() => {
      return store.state.location;
    });

    const showTotalBalance = computed(() => {
      if (
        props.paymentDetail?.registerType?.toLowerCase() === 'acuerdo' &&
        props.paymentDetail?.valueMax === 0
      ) {
        return false;
      }

      return true;
    });

    const isEndorsement = computed((): boolean => {
      return props.paymentDetail?.registerType?.toLowerCase() === 'aval';
    });

    const writeText = computed((): string => {
      return isEndorsement.value ? 'aprobación' : 'acuerdo';
    });

    const obligation = computed(() => {
      return store.getters.getObligationSelected;
    });

    const authenticatedUser = computed(() => {
      return store?.state?.authUser;
    });

    const isDannObligation = computed(
      () =>
        props.paymentDetail.obligationType === ObligationTypes.AVAL_REPOSITORY
    );

    const isNegotiation = computed(
      () => props.paymentDetail.obligationType === ObligationTypes.NEGOTIATION
    );

    const isAvalLince = computed(
      () => props.paymentDetail.obligationType === ObligationTypes.AVAL_LINCE
    );

    const getReferenceAndAgreement = computed<GetReferenceAndAgreementI>(() => {
      return {
        covenant: obligation.value.covenants,
        identification: authenticatedUser.value.identification ?? '',
        obligationId: obligation.value.register_type_id,
        reference: String(props.paymentDetail?.reference),
        existCovenantAttr: false,
        isNpl:
          (isNegotiation.value || isAgreement.value) &&
          obligation.value?.notes?.length === 0,
      };
    });

    const isAgreement = computed(
      () => props.paymentDetail.obligationType === ObligationTypes.AGREEMENT
    );

    const valueGreaterThanZero = computed(() => eventChecktoPay.value > 0);

    const isEndorsementAsociatedToAgreement = computed(
      () => props.paymentDetail.notes !== '' && isAgreement.value
    );

    const showPaymentPlanTable = computed(() => {
      return isDannObligation.value || isEndorsementAsociatedToAgreement.value;
    });

    const clear = () => {
      totalToPay.value = 0;
      checkedTotalToPay.value = paymentType?.minPay;
    };

    const close = () => {
      clear();
      context.emit('close');
    };

    const confirm = () => {
      context.emit('open-modal');
    };

    const eventModalPaymentChannels = () => {
      showModalPaymentChannels.value = true;
    };

    const closeModalPaymentChannels = () => {
      showModalPaymentChannels.value = false;
    };

    const makePayment = () => {
      const maxPayValue: number = props.paymentDetail?.valueMax ?? 0;
      const minPayValue: number =
        totalToPay.value > 0
          ? minimunValueAgreement
          : props.paymentDetail?.valueMin ?? 0;
      const maxPayValueIsZero: boolean = maxPayValue === 0;
      const paymentDetail =
        minPayValue < maxPayValue &&
        eventChecktoPay.value >= maxPayValue &&
        !maxPayValueIsZero
          ? props.paymentDetail?.detailFullTotalPayment
          : props.paymentDetail?.detail;

      if (
        eventChecktoPay.value >= minPayValue &&
        (eventChecktoPay.value <= maxPayValue || maxPayValueIsZero)
      ) {
        showSwalLoading();
        const dataPay: PlaceToPayRequestI = {
          document_type: authenticatedUser.value.document_type,
          identification: authenticatedUser.value.identification,
          payment_platform: `placetopay_${props.paymentDetail?.code_pse}`,
          reference: props.paymentDetail?.reference,
          description: paymentDetail,
          amount: eventChecktoPay.value,
          ip_address: location.value?.ip ?? '8.8.8.8',
          user_agent:
            location.value?.uag ??
            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Edg/92.0.902.62',
        };

        customTransactioncApm(
          $apm,
          'click - realizar-pago-online',
          'custom',
          dataPay
        );
        store
          .dispatch('payFee', dataPay)
          .then((res: PlaceToPayResponseI) => {
            closeSwal();
            const { attributes, message, success } = res;
            addTransaction({
              processUrl: attributes.processUrl,
              requestId: attributes.requestId,
            });
            if (success) {
              const { status, processUrl } = attributes;
              if (status?.status?.toLowerCase() === 'ok') {
                isAppleDevice(iPhoneLink.value as HTMLLinkElement, processUrl);
              } else {
                showSwalError(status.message);
              }
            } else {
              showSwalError(message);
            }
          })
          .catch((error) =>
            showErrors(
              error,
              'Se produjo un error al redireccionar a la plataforma de pago online PSE'
            )
          )
          .finally(() => endTransactionApm());

        return false;
      }

      checkValidationPayFee(maxPayValueIsZero, minPayValue, maxPayValue);
    };

    const checkValidationPayFee = (
      maxPayValueIsZero: boolean,
      minPayValue: number,
      maxPayValue: number
    ) => {
      const message = maxPayValueIsZero
        ? `Por favor digite un valor a pagar mayor al valor mínimo de <strong>${currency(
            minPayValue
          )}</strong>.`
        : `Por favor digite un valor a pagar entre el valor mínimo de <strong>${currency(
            minPayValue
          )}</strong>
        y el valor máximo de <strong>${currency(maxPayValue)}</strong>.`;
      showSwalInfo(message);
    };

    const physicalPayment = () => {
      customTransactioncApm($apm, 'click - pago-en-efectivo', 'custom', {});
      eventModalPaymentChannels();
      endTransactionApm();
    };

    const getDataCertification = (): ClosedObligationI => {
      let data: ClosedObligationI = Object.create({});
      const paymentDetailType = String(
        props.paymentDetail?.obligationType
      ).toString();

      if (
        [
          ObligationTypes.AGREEMENT.toString(),
          ObligationTypes.AVAL_LINCE.toString(),
        ].includes(paymentDetailType)
      ) {
        data = {
          lince: obligation.value.lince,
          id:
            ObligationTypes.AGREEMENT === paymentDetailType
              ? obligation.value.register_type_id
              : obligation.value.lince_aval_id,
          type_id: obligation.value.type_id,
          anchor_id: obligation.value.anchor_id,
        };
      }

      if (ObligationTypes.AVAL_REPOSITORY === paymentDetailType) {
        data = {
          id: obligation.value.register_type_id,
          type_id: obligation.value.type_id,
          lince: obligation.value.lince,
          anchor_id: obligation.value.anchor_id,
        };
      }

      return data;
    };

    const generateCertificate = () => {
      const dataCertificate = getDataCertification();
      showSwalLoading();
      customTransactioncApm(
        $apm,
        'click - generar-certificado-obligacion',
        'custom',
        dataCertificate
      );
      new RequestDocumentService()
        .downloadPdf(dataCertificate)
        .then((response) => {
          closeSwal();
          const { data } = response;
          const res: ResponseDownloadPdfI = disableEncryption()
            ? data
            : EncryptService.decrypt(data);
          const { success, message, attributes } = res.data;
          addTransaction(res.data);
          if (success) {
            const file: File = base64ToFile(
              attributes,
              `Certificado-${dataCertificate.id}.pdf`,
              'application/pdf'
            );
            const newBlob = new Blob([file], {
              type: 'application/pdf',
            });
            const windowUrl = window.URL || window.webkitURL;
            const url = windowUrl.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `Certificado-${dataCertificate.id}.pdf`
            );
            link.click();
            setTimeout(() => {
              windowUrl.revokeObjectURL(url);
            }, 1000);
          } else {
            showSwalError(message);
          }
        })
        .catch((error) => {
          showErrors(
            error,
            'Se produjo un error al generar el certificado, por favor intentelo de nuevo.'
          );
        })
        .finally(() => endTransactionApm());
    };

    return {
      checkedTotalToPay,
      checkValidationPayFee,
      clear,
      close,
      confirm,
      eventChecktoPay,
      makePayment,
      isEndorsement,
      location,
      paymentType,
      showTotalBalance,
      showModalPaymentChannels,
      timestamp,
      totalToPay,
      validatePaymentDate,
      writeText,
      eventModalPaymentChannels,
      closeModalPaymentChannels,
      physicalPayment,
      generateCertificate,
      getDataCertification,
      getReferenceAndAgreement,
      isDannObligation,
      isNegotiation,
      isAgreement,
      valueGreaterThanZero,
      isAvalLince,
      isEndorsementAsociatedToAgreement,
      showPaymentPlanTable,
      iPhoneLink,
      hideFieldAnotherValue,
      canMakePayment,
    };
  },
});
